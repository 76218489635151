import React from 'react';
import styles from './startConnecting.module.scss';
import Section from '@/components/Section';
import { Button } from '@/components/Buttons';
import { getRefLink } from '@/lib/helpers';
import { useTranslation } from 'next-i18next';

import Image from 'next/image';
import bybit_round_img from '#/images/bybit_round.png';
import binance_round_img from '#/images/binance_round.png';
import blofin_round_img from '#/images/blofin_round.png';
import bitget_round_img from '#/images/bitget_round.png';
import woo_round_img from '#/images/woo_round.png';
import phemex_round_img from '#/images/phemex_round.png';
import deribit_round_img from '#/images/deribit_round.png';

const exchangesList = [
    { src: bybit_round_img },
    { src: binance_round_img },
    { src: blofin_round_img },
    { src: bitget_round_img },
    { src: woo_round_img },
    { src: phemex_round_img },
    { src: deribit_round_img },
];

const StartConnectingSection = () => {
    const { t } = useTranslation();
    return (
        <Section>
            <div className={styles.startConnectingSection}>
                <div className={styles.exchangesList}>
                    {exchangesList.map((image, index) => (
                        <Image
                            key={'exchange_logo_key' + index}
                            src={image.src}
                            alt=""
                            className={styles.exchangeLogoImg}
                        />
                    ))}
                </div>
                <div className={styles.startConnectingTextWrapper}>
                    <h2 className={'as-h2'}>{t('start_connecting_section.title' as any)}</h2>
                    <p>{t('start_connecting_section.subtitle' as any)}</p>
                    <Button to={getRefLink()} color={'secondary'} size={'large'} blank={false}>
                        {t('start_connecting_section.button_label' as any)}
                    </Button>
                </div>
            </div>
        </Section>
    );
};

export default React.memo(StartConnectingSection);

StartConnectingSection.displayName = 'StartConnectingSection';
